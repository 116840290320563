import CardBox from "components/CardBox";
import { Wrapper, Section, SectionHeading, DescriptionText, HeadingHead } from "./style";
import ComparisonListColumns from "components/ComparisonListColumns";

const AimsSection = ({pTop, pBottom}: any) => (
  <Section
    className="AimsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <HeadingHead>
        <SectionHeading
          variant="h2"
        >
          The future of 6ix Webites <strong>depends on you</strong>
        </SectionHeading>
        <DescriptionText
          variant="body1"
        >
          We publish our product roadmap. Tell us what we should build next – and get early access.
        </DescriptionText>
      </HeadingHead>
      <ComparisonListColumns />
    </Wrapper>
  </Section>
);

export default AimsSection;