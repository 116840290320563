import { Wrapper, Section, Col, Description, Heading, ImageHolder, AlignHolder, AlignBox } from "./style";
import Image from "../../assets/img05.png"
import RatingStartList from "components/RatingStartList";
import { ListItem } from "@mui/material";
import { StarFilledIcon } from "components/Icons/StarFilledIcon";

const UpdatesSection = ({pTop, pBottom}: any) => (
  <Section
    className="UpdatesSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <AlignHolder>
        <Col>
          <AlignBox>
            <ImageHolder>
              <img src={Image} alt="" />
            </ImageHolder>
          </AlignBox>
        </Col>
        <Col>
          <AlignBox>
            <Heading
              variant="h2"
            >
              The fastest most loved IR website solution on the planet
            </Heading>
            <Description
              variant="body1"
            >
              6ix Websites is the only platform with automatic real-time updates, ensuring investors always access the freshest data. Additionally, our integrated engagement tools empower companies with unprecedented insights into each investor's activity, sentiment, and preferences revolutionizing investor relations in the digital age.
            </Description>
            <RatingStartList status="active">
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
              <ListItem>
                <StarFilledIcon />
              </ListItem>
            </RatingStartList>
          </AlignBox>
        </Col>
      </AlignHolder>
    </Wrapper>
  </Section>
);

export default UpdatesSection;