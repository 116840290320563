import SectionHeader from "components/SectionHeader";
import { Wrapper, Section, Richtext, TextRow, Heading, Description, CustomDivider } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";
import CardBox from "components/CardBox";

const RichtextSection = ({pTop, pBottom}: any) => (
  <Section
    className="RichtextSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        extraClass="headingHead"
        heading="Learn More About 6ix Websites"
      />
      <TwoColumnsGrid
        extraClass="two-columns"
      >
        <CardBox>
          <Richtext>
            <TextRow>
              <Heading
                variant="h3"
              >
                What types of companies can benefit from 6ix Events?
              </Heading>
              <Description
                variant="body1"
              >
                Companies looking to grow their market cap and liquidity, improve investor engagement and enhance their fundraising efforts can benefit from using 6ix Websites.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                How can 6ix Events benefit my company?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Websites provides an integrated investor relations website solution that allows you to showcase your latest company news automatically.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                How do I get started with 6ix Websites?
              </Heading>
              <Description
                variant="body1"
              >
                The best way to get started is by pressing the ‘Get Started” button and using the software to create a website. You can also request a demo and speak to a human who can then help you go live for the first time.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                What are the benefits of using 6ix Website to host my investor website, rather than other platforms?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Websites is custom built for the unique capital markets needs of IR professionals. Whereas other platforms are built for multiple website types, such as B2C or B2B, 6ix Websites automatically integrates with SEDAR, EDGAR, and AI, so that your website is kept up-to-date effortlessly. 
              </Description>
            </TextRow>
          </Richtext>
        </CardBox>
        <CardBox>
          <Richtext>
            <TextRow>
              <Heading
                variant="h3"
              >
                How do I track the performance of my website?
              </Heading>
              <Description
                variant="body1"
              >
                Comprehensive analytics is provided in every package. 
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                How does 6ix Websites integrate with my existing investor communications efforts?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Websites connects seamlessly with your email list and other digital tools, allowing you to capture potential investor contact information and automatically send tailored communication. This integration helps you keep prospective investors engaged with relevant updates and news, ultimately driving increased interest in your investor communication and fundraising efforts.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                Is 6ix Websites suitable for my business?
              </Heading>
              <Description
                variant="body1"
              >
                6ix Websites is suitable for public and private companies seeking to reach investors, attract capital, and improve their reputation in the capital markets.
              </Description>
            </TextRow>
            <CustomDivider />
            <TextRow>
              <Heading
                variant="h3"
              >
                What kind of support is available for users of 6ix Websites?
              </Heading>
              <Description
                variant="body1"
              >
                6ix offer comprehensive support for users of 6ix Websites, including access to a dedicated customer success team, product documentation and ongoing updates to ensure the best possible results.
              </Description>
            </TextRow>
          </Richtext>
        </CardBox>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default RichtextSection;