import { Box, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import SectionHeader from "components/SectionHeader";
import FourColumnsGrid from "components/FourColumnsGrid";
import CardBox from "components/CardBox";
import IconColumn from "components/IconColumn";
import { SearchIcon } from "components/Icons/SearchIcon";
import { PencilIcon } from "components/Icons/PencilIcon";
import { EmailIcon } from "components/Icons/EmailIcon";
import { EnrollIcon } from "components/Icons/EnrollIcon";

const IconsSection = ({pTop, pBottom}: any) => (
  <Section
    className="IonsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader heading="How 6ix Websites Works" />
      <FourColumnsGrid>
        <CardBox>
          <IconColumn heading="Setup your dynamic IR website by entering your stock ticker and tailoring your settings to match your brand" icon={<SearchIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Integrate your data sources so that real-time information is always present, from latest financials to breaking news" icon={<PencilIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Publish your fresh, on-brand, always-up-to date IR website so investors can access it from your domain " icon={<EmailIcon />} />
        </CardBox>
        <CardBox>
          <IconColumn heading="Allow investors to schedule meetings, download key reports, & get in touch" icon={<EnrollIcon />} />
        </CardBox>
      </FourColumnsGrid>
    </Wrapper>
  </Section>
);

export default IconsSection;