import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import CardBox from "components/CardBox";
import FeaturesListColumn from "components/FeaturesListColumn";
import FeaturesList from "components/FeaturesList";
import { TickIcon } from "components/Icons/TickIcon";

const ListingSection = ({pTop, pBottom}: any) => (
  <Section
    className="ListingSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <CardBox>
        <FeaturesListColumn heading="6ix Websites is the only platform with..." hasBorder asIndividual>
          <FeaturesList>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Automatic Content Updates
              </Typography>
              <ListItemText
                primary="Instead of manually updating investor news, reports, and financials, 6ix Websites ensures your content is automatically refreshed with the latest information. This keeps your investors constantly in the loop, establishing trust and transparency."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Comprehensive Investor Reports
              </Typography>
              <ListItemText
                primary="Beyond just viewing content, investors can actively engage with built-in analytics, polls, and forums. This allows you to directly gauge sentiment, field questions, and foster an active investor community."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Responsive, Beautiful & Accurate
              </Typography>
              <ListItemText
                primary="Make it effortless for investors to schedule meetings, download pivotal reports, and communicate with your IR team. By streamlining these actions, you can clearly identify serious investors and cater to their needs promptly with a user-interface that brings honour to your company."
              />
            </ListItem>
          </FeaturesList>
        </FeaturesListColumn>
      </CardBox>
    </Wrapper>
  </Section>
);

export default ListingSection;