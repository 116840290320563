import SectionHeader from "components/SectionHeader";
import { Wrapper, Section, SectionHeading, Col, ColumnsWrapper, Heading, ImageHolder, ReportColumn, Subheading, ButtonPrimary, MainImageWrapper, ColsWrapper, Align } from "./style";
import Image1 from "../../assets/img-icon-18.png"
import Image2 from "../../assets/img-icon-19.png"
import Image3 from "../../assets/img-icon-20.png"
import Image4 from "../../assets/img36.png"

const ReportSection = ({pTop, pBottom}: any) => (
  <Section
    className="ReportSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <ColsWrapper>
        <Col>
          <Align>
            <SectionHeading
              variant="h2"
            >
              The Total Economic Impact of 6ix
            </SectionHeading>
            <ColumnsWrapper>
              <ReportColumn>
                <ImageHolder>
                  <img src={Image1} alt="" />
                </ImageHolder>
                <Heading
                  variant="h3"
                >
                  ROI
                </Heading>
                <Subheading
                  variant="h4"
                >
                  861%
                </Subheading>
              </ReportColumn>
              <ReportColumn>
                <ImageHolder>
                  <img src={Image2} alt="" />
                </ImageHolder>
                <Heading
                  variant="h3"
                >
                  Net Benefits
                </Heading>
                <Subheading
                  variant="h4"
                >
                  $5.8M
                </Subheading>
              </ReportColumn>
              <ReportColumn>
                <ImageHolder>
                  <img src={Image3} alt="" />
                </ImageHolder>
                <Heading
                  variant="h3"
                >
                  Payback Time
                </Heading>
                <Subheading
                  variant="h4"
                >
                  &lt; 1 month
                </Subheading>
              </ReportColumn>
            </ColumnsWrapper>
            <ButtonPrimary>
              View Report
            </ButtonPrimary>
          </Align>
        </Col>
        <Col>
          <Align>
            <MainImageWrapper>
              <img src={Image4} alt="" />
            </MainImageWrapper>
          </Align>
        </Col>
      </ColsWrapper>
    </Wrapper>
  </Section>
);

export default ReportSection;