import { Box, Typography } from "@mui/material";
import CourseDropdown from "components/CourseDropdown";
import { Wrapper, Section, BgWrapper, Heading } from "./style";
import SectionMainHeader from "components/SectionMainHeader";
import ButtonsFooter from "components/ButtonsFooter";
import BgImage from "../../assets/bg-img01.jpg"
import LandingVideo from "components/LandingVideo";

const IntroSection = ({}: any) => (
  <Section className="IntroSection">
    <Wrapper>
      <CourseDropdown label="WEBSITES" id="intro-website" />
      <SectionMainHeader
        size="730px"
        description="Effortlessly keep your IR website fresh & up-to-date with 6ix Websites, the only investor relations website platform automatically integrated with SEDAR, EDGAR, and Artificial Intelligence."
      >
        <Heading
          variant="h1"
        >
          Does your IR website <strong>update itself?</strong>
        </Heading>
      </SectionMainHeader>
      <ButtonsFooter primaryLabel="GET IN TOUCH" secondaryLink="#" secondaryLabel="EXPLORE OR BUILD A WEBSITE" primaryLink="#" />
      <LandingVideo />
    </Wrapper>
    <BgWrapper>
      <img src={BgImage} alt="" />
    </BgWrapper>
  </Section>
);

export default IntroSection;