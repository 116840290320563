import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Anchor, ButtonDropdownWrapper, ButtonOpener, CourseMenu } from './style';
import { MonitorIcon } from 'components/Icons/MonitorIcon';
import { CaretIcon } from 'components/Icons/CaretIcon';
import { Link } from '@mui/material';

export default function CourseDropdown({label, id} : any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event : any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ButtonDropdownWrapper>
      <ButtonOpener id={id} aria-controls={`${label}-menu`} aria-haspopup="true" aria-expanded={Boolean(anchorEl)} onClick={handleClick}>
        <MonitorIcon />
        {label}
        <i className='icn-wrap'>
          <CaretIcon />
        </i>
      </ButtonOpener>
      <CourseMenu id={`${label}-menu`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Anchor href="https://ai.6ix.com/">AI</Anchor>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Anchor href="https://events.6ix.com/">EVENTS</Anchor>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Anchor href="https://leads.6ix.com/">LEADS</Anchor>
        </MenuItem>
      </CourseMenu>
    </ButtonDropdownWrapper>
  );
}