import { Box } from "@mui/material";
import { Course } from "pages/Course";
import { Home } from "pages/Home";
import { Route, Routes } from "react-router-dom";
import CallbackPage from "pages/CallbackPage";
import { CourseHome } from "pages/CourseHome";
import { Landing } from "pages/Landing";

export const UnauthorizedRoutes = () => (
  <Box mt={"50px"}>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/course/:courseId" element={<Course />} />
      <Route path="/course/:courseId/home" element={<CourseHome />} />
      <Route path="/course/:courseId/:videoId" element={<Course />} />
      <Route path="/callback" element={<CallbackPage />} />
    </Routes>
  </Box>
);
