import { Key, Label } from "@mui/icons-material";
import {
  Box,
  Icon,
  Link,
  List,
  ListItemButton,
  Button,
  ListItemText,
} from "@mui/material";
import { ReactChild, ReactNode } from "react";
import { HomeIcon } from "../../components/Icons/HomeIcon";
import { VidRecorderIcon } from "../../components/Icons/VidRecorderIcon";
import { ButtonWrapper, LinkPrimary, SidebarWrapper, Wrapper } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

const links = [
  // {
  //   label: "home",
  //   icon: <HomeIcon />,
  //   url: "/",
  // },
  {
    label: "Website",
    icon: <VidRecorderIcon />,
    url: "/Website",
  },
  // {
  //   label: "statistics",
  //   icon: <StatisticsIcon />,
  //   url: "/statisitcs",
  // },
  // {
  //   label: "api keys",
  //   icon: <KeyRotatedIcon />,
  //   url: "/api-keys",
  // },
  // {
  //   label: "sources",
  //   icon: <PlugIcon />,
  //   url: "/sources",
  // },
  // {
  //   label: "destination",
  //   icon: <TargetIcon />,
  //   url: "/destination",
  // },
  // {
  //   label: "dbt cloud integration",
  //   icon: <DbtIcon />,
  //   url: "/dbt-cloud-integration",
  // },
  // {
  //   label: "project settings",
  //   icon: <GearIcon />,
  //   url: "/project-settings",
  // },
  // {
  //   label: "geo data resolver",
  //   icon: <GlobeIcon />,
  //   url: "/geo-data-resolver",
  // },
  // {
  //   label: "download config",
  //   icon: <DownloadIcon />,
  //   url: "/download-config",
  // },
];

export const Sidebar = ({ icon, email, status, setSidebar }: any) => {
  const [user, loading] = useAuthState(auth);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <SidebarWrapper className="SidebarWrapper">
      <Wrapper>
        {/* {user && ( */}
          <ButtonWrapper>
            <LinkPrimary
              className="sbwLink"
              onClick={() => {
                navigate("/create");
                setSidebar(false);
              }}
            >
              REQUEST A DEMO
            </LinkPrimary>
          </ButtonWrapper>
        {/* )} */}
        <nav aria-label="sidebar navigation">
          <List className="side-nav-list" disablePadding>
            {links.map((link, key) => (
              <ListItemButton
                key={key}
                component="a"
                className="sbwListButton"
              >
                <ListItemText
                  onClick={() => {
                    navigate("/");
                    setSidebar(false);
                  }}
                  className={
                    link.label !== "home" && pathname.includes(link.url)
                      ? "sbwButtonText active"
                      : pathname === link.url
                      ? "sbwButtonText active"
                      : "sbwButtonText"
                  }
                  primary={link.label}
                />
                {/* <i className="navicon">{link.icon}</i> */}
              </ListItemButton>
            ))}
          </List>
        </nav>
      </Wrapper>
    </SidebarWrapper>
  );
};
