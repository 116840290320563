import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-left: 15px;

  @media (min-width: 768px){
    padding-left: 0;
    padding-left: 0;
  }
`;

export const Wrapper = styled(Box)`

  // @media (min-width: 768px){
  //   margin-right: -100px;
  //   margin-left: -100px;
  // }
`;

export const MainHeading = styled(Typography)`
  text-align: center;
  font: 800 30px/1.167 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 42px;

  @media (min-width: 768px){
    font-size: 35px;
  }
  @media (min-width: 1200px){
    font-size: 40px;
  }
  @media (min-width: 1530px){
    font-size: 50px;
  }
`;

export const ImageWrapper = styled(Box)`
  img{
    width: 100%;
    height: auto;
  }
`;