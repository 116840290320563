import { styled, Typography, Box } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 96px 15px 0;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export const BgWrapper = styled(Box)`
  z-index: -1;
  height: 60%;
  
  &,
  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  img{
    object-fit: cover;
    height: 100vh;
    object-position: 50% 50%;
  }
`;

export const Heading = styled(Typography)`
  font: 900 40px/1.25 'Playfair Display', 'Times New Roman', Times, serif;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 768px){
    font-size: 40px;
  }
  @media (min-width: 992px){
    font-size: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 1200px){
    font-size: 60px;
  }

  strong{
    color: #E3C050;
  }
`;