import { CustomDivider, LaningPageWrapper } from "./style";
import IntroSection from "components/IntroSection";
import BlogsSection from "components/BlogsSection";
import IconsSection from "components/IconsSection";
import PresentationSection from "components/PresentationSection";
import ListingSection from "components/ListingSection";
import UpdatesSection from "components/UpdatesSection";
import CapabilitiesSection from "components/CapabilitiesSection";
import ConditionsSection from "components/ConditionsSection";
import MarketBlogsSection from "components/MarketBlogsSection";
import CoursesSection from "components/CoursesSection";
import CardBox from "components/CardBox";
import CourseColumn from "components/CourseColumn";
import SectionHeader from "components/SectionHeader";
import ThreeColumnsGrid from "components/ThreeColumnsGrid";
import ProductColumnsSection from "components/ProductColumnsSection";
import SponsorsSection from "components/SponsorsSection";
import TestimonialsSection from "components/TestimonialsSection";
import VideosSection from "components/VideosSection";
import Image1 from "../../assets/img09.jpg"
import Image2 from "../../assets/img10.jpg"
import Image3 from "../../assets/img11.jpg"
import Image4 from "../../assets/img12.jpg"
import Image5 from "../../assets/img13.jpg"
import Image6 from "../../assets/img14.jpg"
import Image7 from "../../assets/img15.jpg"
import Image8 from "../../assets/img16.jpg"
import Image9 from "../../assets/img17.jpg"
import Image10 from "../../assets/img18.jpg"
import Image11 from "../../assets/img19.jpg"
import Image12 from "../../assets/img20.jpg"
import Image13 from "../../assets/img21.jpg"
import Image14 from "../../assets/img22.jpg"
import Image15 from "../../assets/img23.jpg"
import Image16 from "../../assets/img24.jpg"
import Image17 from "../../assets/img25.jpg"
import Image18 from "../../assets/img26.jpg"
import Image19 from "../../assets/img27.jpg"
import Image20 from "../../assets/img28.jpg"
import Image21 from "../../assets/img29.jpg"
import Image22 from "../../assets/img38.jpg"
import Image23 from "../../assets/img39.jpg"
import Image24 from "../../assets/img40.jpg"
import Image25 from "../../assets/img41.jpg"
import Image26 from "../../assets/img42.jpg"
import Image27 from "../../assets/img43.jpg"
import Image28 from "../../assets/img44.jpg"
import Image29 from "../../assets/img45.jpg"
import Image30 from "../../assets/img46.jpg"
import AimsSection from "components/AimsSection";
import PricingSection from "components/PricingSection";
import ReportSection from "components/ReportSection";
import RichtextSection from "components/RichtextSection";
import FormSection from "components/FormSection";
import ScheduleSection from "components/ScheduleSection";
import StickyButtons from "components/StickyButtons";

export const Landing = () => {
  
  return (
    <LaningPageWrapper>
      <IntroSection />
      <BlogsSection pTop="132px" pBottom="0" />
      <IconsSection pTop="132px" pBottom="0" />
      <PresentationSection pTop="70px" pBottom="0" />
      <ListingSection pTop="130px" pBottom="0" />
      <UpdatesSection pTop="70px" pBottom="0" />
      <CapabilitiesSection pTop="125px" pBottom="0" />
      <ConditionsSection pTop="127px" pBottom="0" />
      <MarketBlogsSection pTop="214px" pBottom="0" />
      <CoursesSection pTop="152px" pBottom="0"><SectionHeader
        headingLarge
        heading="Setup your dyanmic IR website"
      />
        <ThreeColumnsGrid>
          <CardBox>
            <CourseColumn
              imageUrl={Image1}
              heading="Upload your logo"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image2}
              heading="Setup your brand colours"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image3}
              heading="Setup your typography"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image4}
              heading="Host on your domain"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image5}
              heading="Add your management team"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image6}
              heading="Setup your board"
            />
          </CardBox>
        </ThreeColumnsGrid>
      </CoursesSection>
      <CustomDivider />
      <CoursesSection pTop="0" pBottom="0"><SectionHeader
        headingLarge
        heading="Integrate your data-sources"
      />
        <ThreeColumnsGrid>
          <CardBox>
            <CourseColumn
              imageUrl={Image7}
              heading="Integrate with SEDAR"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image8}
              heading="Integrate with EDGAR"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image9}
              heading="Integrate with Business Wire"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image22}
              heading="Integrate with HubSpot"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image23}
              heading="Integrate with Mailchimp"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image24}
              heading="Integrate with other 6ix products"
            />
          </CardBox>
        </ThreeColumnsGrid>
      </CoursesSection>
      <CustomDivider />
      <CoursesSection pTop="0" pBottom="0"><SectionHeader
        headingLarge
        heading="Publish your always-fresh, on-brand & up-to-date website"
      />
        <ThreeColumnsGrid>
          <CardBox>
            <CourseColumn
              imageUrl={Image10}
              heading="Your website on your domain"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image11}
              heading="Send emails from your domain"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image12}
              heading="Automatically updated"
            />
          </CardBox>
        </ThreeColumnsGrid>
      </CoursesSection>
      <CustomDivider />
      <CoursesSection pTop="0" pBottom="0">
        <SectionHeader
          headingLarge
          heading="Allow investors to schedule meetings, download key reports, & get in touch"
        />
        <ThreeColumnsGrid>
          <CardBox>
            <CourseColumn
              imageUrl={Image13}
              heading="Schedule meetings"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image14}
              heading="Download key reports"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image15}
              heading="Allow investors to get in touch"
            />
          </CardBox>
        </ThreeColumnsGrid>
      </CoursesSection>
      <CustomDivider />
      <CoursesSection pTop="0" pBottom="0">
        <SectionHeader
          headingLarge
          heading="Analyze your Website Performance on the Aggregate and Individual Level"
        />
        <ThreeColumnsGrid>
          <CardBox>
            <CourseColumn
              imageUrl={Image16}
              heading="Investor Composition"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image17}
              heading="Shareholder Composition"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image18}
              heading="Sentiment Composition"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image19}
              heading="Geography information"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image20}
              heading="Firmographic information"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image21}
              heading="Demographic information"
            />
          </CardBox>
        </ThreeColumnsGrid>
      </CoursesSection>
      <CustomDivider />
      <CoursesSection pTop="0" pBottom="0"><SectionHeader
        headingLarge
        heading="Follow Up With Best-Fit Investors"
      />
        <ThreeColumnsGrid>
          <CardBox>
            <CourseColumn
              imageUrl={Image25}
              heading="Receive Meeting Requests"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image26}
              heading="Review Investor Information"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image27}
              heading="Accept or Reject Meetings"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image28}
              heading="Automatic Calendar Integration"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image29}
              heading="Easy Investor Outreach"
            />
          </CardBox>
          <CardBox>
            <CourseColumn
              imageUrl={Image30}
              heading="Keep Track of Deal Stages"
            />
          </CardBox>
        </ThreeColumnsGrid>
      </CoursesSection>
      <CustomDivider />
      <ProductColumnsSection pTop="0" pBottom="70px" />
      <SponsorsSection pTop="85px" pBottom="0" />
      <TestimonialsSection pTop="130px" pBottom="0" />
      <VideosSection pTop="130px" pBottom="65px" />
      <ScheduleSection pTop="65px" pBottom="65px" />
      <AimsSection pTop="65px" pBottom="65px" />
      {/* <PricingSection pTop="65px" pBottom="65px" /> */}
      <ReportSection pTop="65px" pBottom="65px" />
      <RichtextSection pTop="65px" pBottom="65px" />
      <FormSection pTop="65px" pBottom="65px" />
      <StickyButtons buttonPrimaryLabel="GET IN TOUCH" buttonSecondaryLabel="ATTEND & HOST EVENTS" />
    </LaningPageWrapper>
  );
};
