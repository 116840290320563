import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";
import CardBox from "components/CardBox";
import FeaturesListColumn from "components/FeaturesListColumn";
import FeaturesList from "components/FeaturesList";
import { TickIcon } from "components/Icons/TickIcon";

const PresentationSection = ({pTop, pBottom}: any) => (
  <Section
    className="PresentationSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <TwoColumnsGrid sameheight="false">
        <CardBox>
          <FeaturesListColumn heading="The Old Way">
            <FeaturesList>
              <ListItem>
                <ListItemText
                  primary="Your investor website is static and requires frequent manual updates"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="You're unsure about the current sentiment and engagement level of your website visitors."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="It's challenging to differentiate casual visitors from serious potential investors on your site."
                />
              </ListItem>
            </FeaturesList>
          </FeaturesListColumn>
        </CardBox>
        <CardBox>
          <FeaturesListColumn heading="The New Way" status="active">
            <FeaturesList>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="6ix Websites automatically updates content in real-time from integrated data sources."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Investors engage with built-in tools on 6ix Websites, providing direct feedback and sentiment insights."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Investors can effortlessly schedule meetings and download key reports, showing clear intent and interest in your company."
                />
              </ListItem>
            </FeaturesList>
          </FeaturesListColumn>
        </CardBox>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default PresentationSection;