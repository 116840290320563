import { Wrapper, Section, Col, PostsWrapper } from "./style";
import SectionHeader from "components/SectionHeader";
import { ImageHolder } from "components/BlogColumn/style";
import CardBox from "components/CardBox";
import PostColumn from "components/PostColumn";
import Image1 from "../../assets/img06.jpg"
import Image2 from "../../assets/img07.jpg"
import Image3 from "../../assets/img08.jpg"

const MarketBlogsSection = ({pTop, pBottom}: any) => (
  <Section
    className="MarketBlogsSection"
    sx={{
      paddingTop: `calc(${pTop} * 0.65)`,
      paddingBottom: `calc(${pBottom} * 0.65)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <SectionHeader
        heading="Reach investors, attract capital and improve your reputation in the capital markets"
        tagline="6ix Websites helps you&hellip;"
      />
      <PostsWrapper>
        <Col>
          <CardBox className="CardBox">
            <PostColumn
              imageUrl={Image1}
              tagline="REACH MORE INVESTORS"
              heading="Comprehensive Investor Profiles"
              excerpt="Know exactly who is on your website follow up with them through marketing emails and SMS messages."
            />
          </CardBox>
        </Col>
        <Col>
          <CardBox className="CardBox">
            <PostColumn
              imageUrl={Image2}
              tagline="RAISE CAPITAL"
              heading="Inbound Investor Meetings"
              excerpt="Allow investors to request a meeting with your management team at any time. Receive their request, evaluate whether the meeting should happen and then reject or accept the meeting."
            />
          </CardBox>
        </Col>
        <Col
          className="full"
        >
          <CardBox className="CardBox">
            <PostColumn
              view="list"
              imageUrl={Image3}
              tagline="IMPROVE YOUR REPUTATION"
              heading="Data-Driven Investor Reports"
              excerpt="At a glance, understand your investor audience based on information like investor type, cheque size, shareholder status, investor sentiment and more, with an ability to see the aggregate or to drill down into the individual investor profile."
            />
          </CardBox>
        </Col>
      </PostsWrapper>
    </Wrapper>
  </Section>
);

export default MarketBlogsSection;